import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import {
  DatoGatsbyImage,
  DatoStructuredText,
} from '@/features/common-blocks'
import { absoluteFill, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.TeamMemberArticleFragment | null
  layout: 'LIGHTBOX' | 'PAGE'
}

const TeamMemberArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const styles = {
    article: css`
      display: grid;
      grid-template-columns: auto 1fr;
      text-decoration: none;
      padding: var(--row-72);
      column-gap: var(--gtr-72);
      background: #fff;
      ${layout === 'PAGE' &&
      css`
        padding: var(--row-108) var(--margin);
      `}
      ${mq().ms} {
        column-gap: var(--gtr-54);
      }
    `,
    imageContainer: css`
      position: relative;
      grid-row: 1 / span 2;
      display: flex;
      align-self: flex-start;
      border-radius: 50%;
      overflow: hidden;
      ${mq().ls} {
        grid-row-end: span 1;
      }
      &:before {
        content: '';
        ${absoluteFill};
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 1;
      }
    `,
    image: css`
      width: calc(var(--fs-144) * 2);
    `,
    heading: css`
      align-self: flex-end;
      ${mq().ls} {
        align-self: center;
      }
    `,
    name: css`
      font-size: var(--fs-60);
      color: ${colors.tealDark};
      margin: 0;
      padding: 0.5em 0 0;
    `,
    jobTitle: css`
      margin: 0;
      padding: 1em 0 0;
      color: ${colors.teal};
      text-decoration: uppercase;
      font-size: var(--fs-24);
      font-weight: 300;
    `,
    bio: css`
      border-top: 1px solid ${colors.gray75};
      padding-top: 1em;
      color: ${colors.gray40};
      margin-top: 2em;
      ${mq().ls} {
        grid-column: 1 / -1;
      }
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <div css={styles.imageContainer}>
        <DatoGatsbyImage
          css={styles.image}
          image={data?.photo?.gatsbyImageData}
          alt={data?.photo?.alt || `${data?.name} | ${data?.jobTitle}`}
        />
      </div>
      <div css={styles.heading}>
        <h3 css={styles.name}>{data?.name}</h3>
        <h4 css={styles.jobTitle}>{data?.jobTitle}</h4>
      </div>
      <div css={styles.bio}>
        <DatoStructuredText data={data?.bio} />
      </div>
    </article>
  )
}

export const TeamMemberArticleFragment = graphql`
  fragment TeamMemberArticle on DatoCmsTeamMember {
    __typename
    id: originalId
    name
    jobTitle
    photo {
      gatsbyImageData(
        width: 480
        imgixParams: { ar: "1:1", crop: "focalpoint", fit: "crop" }
      )
      alt
    }
    bio {
      value
    }
    slug
    seoMetaTags {
      tags
    }
  }
`

export default TeamMemberArticle
